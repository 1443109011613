define("discourse/plugins/discourse-adplugin/discourse/controllers/admin-plugins-house-ads-show", ["exports", "@ember/controller", "@ember/object/computed", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/computed", "discourse/mixins/buffered-content", "I18n"], function (_exports, _controller, _computed, _service, _ajax, _ajaxError, _computed2, _bufferedContent, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend((0, _bufferedContent.bufferedProperty)("model"), {
    adminPluginsHouseAds: (0, _controller.inject)("adminPlugins.houseAds"),
    router: (0, _service.inject)(),
    saving: false,
    savingStatus: "",
    nameDirty: (0, _computed2.propertyNotEqual)("buffered.name", "model.name"),
    htmlDirty: (0, _computed2.propertyNotEqual)("buffered.html", "model.html"),
    visibleToAnonsDirty: (0, _computed2.propertyNotEqual)("buffered.visible_to_anons", "model.visible_to_anons"),
    visibleToLoggedInDirty: (0, _computed2.propertyNotEqual)("buffered.visible_to_logged_in_users", "model.visible_to_logged_in_users"),
    dirty: (0, _computed.or)("nameDirty", "htmlDirty", "visibleToLoggedInDirty", "visibleToAnonsDirty"),
    disableSave: (0, _computed.not)("dirty"),
    actions: {
      save() {
        if (!this.get("saving")) {
          this.setProperties({
            saving: true,
            savingStatus: _I18n.default.t("saving")
          });
          const data = {},
            buffered = this.get("buffered"),
            newRecord = !buffered.get("id");
          if (!newRecord) {
            data.id = buffered.get("id");
          }
          data.name = buffered.get("name");
          data.html = buffered.get("html");
          data.visible_to_logged_in_users = buffered.get("visible_to_logged_in_users");
          data.visible_to_anons = buffered.get("visible_to_anons");
          (0, _ajax.ajax)(newRecord ? `/admin/plugins/pluginad/house_creatives` : `/admin/plugins/pluginad/house_creatives/${buffered.get("id")}`, {
            type: newRecord ? "POST" : "PUT",
            data
          }).then(ajaxData => {
            this.commitBuffer();
            this.set("savingStatus", _I18n.default.t("saved"));
            if (newRecord) {
              const model = this.get("model");
              model.set("id", ajaxData.house_ad.id);
              const houseAds = this.get("adminPluginsHouseAds.model");
              if (!houseAds.includes(model)) {
                houseAds.pushObject(model);
              }
              this.router.transitionTo("adminPlugins.houseAds.show", model.get("id"));
            }
          }).catch(_ajaxError.popupAjaxError).finally(() => {
            this.setProperties({
              saving: false,
              savingStatus: ""
            });
          });
        }
      },
      cancel() {
        this.rollbackBuffer();
      },
      destroy() {
        const houseAds = this.get("adminPluginsHouseAds.model");
        const model = this.get("model");
        if (!model.get("id")) {
          this.router.transitionTo("adminPlugins.houseAds.index");
          return;
        }
        (0, _ajax.ajax)(`/admin/plugins/pluginad/house_creatives/${model.get("id")}`, {
          type: "DELETE"
        }).then(() => {
          houseAds.removeObject(model);
          this.router.transitionTo("adminPlugins.houseAds.index");
        }).catch(_ajaxError.popupAjaxError);
      }
    }
  });
});